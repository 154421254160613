@use 'variables' as v;

.banner {
    overflow-y: hidden;
    position: fixed;
    background-color: #fff;
    width: 50%;
    height: 100vh;
    padding: 32px 16px 32px 32px;

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .logo {
            a {
                img {
                    width: 27px;
                }
            }
        }

        .navbar {
            padding-top: 0;

            ul.navbar-links {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 5px;

                    a {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        font-family: v.$body-font;
                        font-size: v.$font-size-m;
                        line-height: 1;

                        &:hover {
                            transform: v.$hover-scale;
                        }
                    }
                }
            }
        }
    }

    .carousel {
        width: 100%;
        height: 70vh;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .carousel-images {
            position: relative;
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: opacity 0.3s ease-in-out;

            &.active {
                opacity: 1;
            }

            &:not(.active) {
                opacity: 0;
            }
        }
    }

    .quotation {
        position: absolute;
        bottom: 27px;
        left: 32px;

        a {
            img {
                height: 50px;

                &:hover {
                    transform: v.$hover-scale;
                }
            }
        }
    }
}