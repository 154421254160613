$body-font: 'NotoSans-Regular';
$body-font-s: 'NotoSans-Condensed';
$title-font:'NotoSans-Medium';

$font-size-s:13px;
$font-size-m:17px;

$hover-scale: scale(1.02);

$content-padding:32px 32px 32px 16px;
$mobile-padding:25px 20px;

$break-point:830px;