.preloader {
    background-image: url('../assets/img/preloader-background.webp');
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    &.fade-in {
        opacity: 1;
        transition: opacity 2s ease-in-out;
    }

    &.fade-out {
        opacity: 0;
        transition: opacity 0.8s ease-in-out;
    }

    .preloader-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 70px;
        animation: scaleUp 3.5s ease-in-out forwards;

        &.scaled {
            transform: scale(37);
        }
    }

    @keyframes scaleUp {
        0% {
            transform: scale(1);
            opacity: 0;
            filter: blur(10px);
        }

        70% {
            transform: scale(1);
            opacity: 0.6;
            filter: blur(0);
        }

        100% {
            transform: scale(37);
        }
    }

    .logo {
        max-width: 100%;
        max-height: 100%;
    }
}