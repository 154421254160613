@use '../components/variables' as v;

.projects {
    padding: 32px 32px 32px 32px;

    .project-card {
        margin-bottom: 35px;
        padding-right: 10px;
        text-align: right;

        .project-image {
            width: 100%;
            max-height: 650px;
            object-fit: cover;

            &:hover {
                transform: v.$hover-scale;
            }
        }

        .project-title {
            font-family: 'NotoSans-Condensed';
            font-size: 8px;
            line-height: 2;
        }

        .project-hashtags {
            font-family: 'NotoSans-Condensed';
            font-size: 8px;
            line-height: 1;
        }
    }
}

@media screen and (max-width:v.$break-point) {
    .projects {
        padding: 25px 30px 25px 30px;

        .project-card {
            margin-bottom: 40px;
            padding-right: 0px;

            .project-title {
                margin-top: 10px;
                line-height: 1.5;
            }
        }
    }
}