@use 'variables' as v; 

.mobile-navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.mobile-navbar-toggler {
    position: fixed;
    bottom: -5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    img {
        width: 80px;
        height: 40px;
    }

}

.navbar-collapse {
    display: none;
}

.open {
    display: block;
}

.mobile-navbar-links {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    .mobile-navbar-link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 20px;
        background-color: #fff;
        border-top: 1px solid #000;
        font-size: 23px;
        line-height: 1;
        z-index: 9;

        img {
            width: 25px;
            margin-right: 20px;
        }

        &:nth-child(1) {
            transition-delay: 0.2s;
        }

        &:nth-child(2) {
            transition-delay: 0.4s;
        }

        &:nth-child(3) {
            transition-delay: 0.6s;
        }

        &:nth-child(4) {
            transition-delay: 0.8s;
        }
    }

    .mobile-navbar-link:hover {
        transform: v.$hover-scale;
    }
}